import { TimeLine, SliderSteps } from '@itau-loans-www/shopping/src/components'
import SummaryLayout from '@itau-loans-www/shopping/src/layouts/Summary'

import './finished.scss'
import useFinished from './hooks'
import { sliderData } from './sliderData'

const FormalizationFinished = () => {
  useFinished()
  return (
    <SummaryLayout isHeaderColorBrand>
      <div className="finished container">
        <section className="finished__intro">
          <h1 className="finished__intro-title">
            Falta pouco! Em instantes, você vai receber um SMS para formalizar a
            sua proposta
          </h1>
          <p className="finished__intro-subtitle">
            Confira abaixo o passo a passo da formalização
          </p>
        </section>
        <SliderSteps sliderData={sliderData} />
        <section className="finished__timeline">
          <TimeLine
            className="finished__timeline-wrapper"
            title="Confira os próximos passos para você finalizar seu empréstimo"
            subtitle="Próximos passos"
            steps={[
              {
                title: 'Realizar simulação',
                status: 'done'
              },
              {
                title: 'Confirmar dados cadastrais',
                status: 'done'
              },
              {
                title: 'Finalizar contratação',
                status: 'ongoing'
              },
              {
                title: 'Receber o dinheiro',
                status: 'open'
              }
            ]}
          />
        </section>
      </div>
    </SummaryLayout>
  )
}

export default FormalizationFinished
