export const sliderData = [
  {
    img: 'formalization-1',
    title:
      'Acesse o link que te enviamos via SMS para finalizar sua contratação',
    text: 'Depois de acessar, autorize e revise as condições do seu contrato. Deixe o documento de identidade disponível para a próxima etapa.'
  },
  {
    img: 'formalization-2',
    title: 'Agora é hora de tirar a foto do documento de identidade.',
    text: 'Muita atenção! É preciso tirar uma foto da frente do seu documento de identidade e uma do verso. Tenha cuidado para que a foto não saia tremida.'
  },
  {
    img: 'formalization-3',
    title: 'Vamos precisar da sua selfie como prova de vida.',
    text: 'Tenha atenção para os olhos não saírem fechados e tire a foto em um lugar claro. Essa etapa é muito importante.'
  },
  {
    img: 'formalization-4',
    title: 'Tudo pronto, sua contratação finalizada!',
    text: 'Em breve você receberá seu empréstimo e seu contrato será enviado para o seu e-mail. Caso ocorra alguma pendência, entraremos em contato.'
  }
]
